


























import './styles/AddMemberPopup.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PopupActions} from '@store/Popup';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';
import { IProjectTeamPopupsState } from '@store/modules/project-team/modules/popups/Interfaces';

const NSProjectTeamPopups = namespace('storeProjectTeam/storeProjectTeamPopups');

@Component({
    name: 'AddMemberPopup',

    components: {
        FindMemberForm: () => import('./FindMemberForm.vue'),
        InviteMemberForm: () => import('./InviteMemberForm.vue'),
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler/BaseViewToggler.vue'),
    },
})
export default class AddMemberPopup extends Vue {
    $refs!: {
        basePopup: any
        findMemberForm: any,
        inviteMemberForm: any,
    };

    @NSProjectTeamPopups.Getter('addMemberPopupToggler') addMemberPopupToggler!: boolean;
    @NSProjectTeamPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IProjectTeamPopupsState>
    ) => void;

    views = [
        { label: 'Найти', value: 'find' },
        { label: 'Пригласить', value: 'invite' },
    ];
    currentView = {} as { label: string; value: string; };

    @Watch('addMemberPopupToggler')
    watchAddMemberPopupToggler() {
        if (this.addMemberPopupToggler) this.$refs.basePopup.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    created() {
        this.currentView = this.views[0];
    }

    get postButtonText() {
        return this.currentView.value === 'find' ? 'Найти' : 'Пригласить';
    }

    changeView(view: { label: '', value: '' }) {
        this.currentView = view;
    }

    sendForm() {
        const formRef: string = `${this.currentView.value}MemberForm`;
        this.useFormToPostData(this.$refs[formRef]);
    }

    useFormToPostData(form: any) {
        form.sendForm().then(() => {
            this.closePopup();
            form.resetData();
        });
    }

    closePopup() {
        this.togglePopup({
            popupToggler: 'addMemberPopupToggler',
            state: false,
        });
    }
}
